.top {
  padding-top: 54.66px;
  background-color: black;
}
@media (max-width: 900px) {
  .top {
    padding-top: 42px;
  }
}
button.submitBtn {
  width: 100%;
}
