.about {
  &__paragraph {
    background-color: #1b1b1b;
    color: white;
    padding: 7% 13%;
    margin-top: -4px;
    p {
      font-family: "Inter";
      font-weight: 300;
      line-height: 1.6;
      margin: 5px 0px;
    }
  }
  &__video {
    width: 100%;
    height: 315px;
    object-fit: cover;
    margin: auto;
  }
  &__groupPicture {
    width: 100%;
    border: transparent;
  }
  .phoneScreen {
    background-color: black;
    padding-top: 54.66px;
  }
}
.secondParagraph {
  padding: 3% 13%;
}
@media (max-width: 900px) {
  .about {
    &__paragraph {
      padding: 2rem 5%;
    }
    &__video {
      height: 60vh;
      padding: 0;
      height: 250px;
    }
  }
}
@media (max-width: 700px) {
  .about {
    &__video {
      height: 40vh;
    }
  }
}
@media (max-width: 400px) {
  .about {
    &__paragraph {
      p {
        font-size: 3.5vw;
      }
    }
    &__video {
      height: 140px;
    }
  }
}
