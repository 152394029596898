.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  label {
    color: white;
    font-family: "Inter";
    font-size: 0.8rem;
    margin-bottom: 8px;
  }
  input {
    height: 45px;
    background-color: #0e0e0e68;
    padding: 10px;
    border: 1px solid white;
    font-size: 1rem;
    color: white;
    font-family: "Inter";
  }
  p {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
}
.fullInput {
  width: 100%;
}

.halfInput {
  width: 48%;
}
.textArea {
  width: 100%;
  textarea {
    height: 130px;
    background-color: #0e0e0e68;
    padding: 10px;
    font-size: 1rem;
    font-family: "Inter";
    color: white;
    border: 1px solid white;
  }
}
@media (max-width: 900px) {
  .halfInput {
    width: 100%;
  }
}
