.container {
  display: flex;
  align-items: center;
  padding: 6% 10%;
  background-color: black;
  justify-content: space-between;
  .title {
    display: flex;
    justify-content: center;
    h1 {
      color: #d50200;
      font-family: "Bowlby One";
      font-size: 4vw;
      width: 381px;
      line-height: 1.3;
    }
  }
  .stars {
    display: flex;
    justify-content: center;
    .star {
      width: 8vw;
      margin: 0px 4%;
      animation: rotation 20s infinite linear;
    }
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.reversedContainer {
  flex-direction: row-reverse;
  text-align: end;
}
@media (max-width: 900px) {
  .container {
    padding: 10% 6%;
  }
}
@media (max-width: 650px) {
  .container {
    .title {
      width: 50%;
      h1 {
        font-size: 5vw;
        width: 100%;
      }
    }
  }
}
