*,
::before,
::after {
  box-sizing: border-box;
  /* transition: 0.3s; */
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
  font-family: Inter;
  background: #000;
}
a,
button,
[role="button"] {
  cursor: pointer;
}