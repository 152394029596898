.backGround {
  background-color: #1b1b1b;
  display: flex;
  align-items: center;
  justify-content: center;
  .imageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    .photo {
      // height: ;
      &__img {
        filter: grayscale(100%);
        width: 100%;
        height: 100%;
        aspect-ratio: 3/2;
        &:hover {
          filter: grayscale(0%);
        }
      }
      &:nth-of-type(5) {
        width: 62%;
      }

      &:nth-of-type(6) {
        width: 138%;
        margin-left: -38%;
      }
    }
  }
}
.top {
  padding-top: 54.66px;
  background-color: black;
}

@media (max-width: 900px) {
  .top {
    padding-top: 42px;
    background-color: black;
  }
  .backGround {
    .imageGrid {
      width: 90%;
      .photo {
        &__img {
          filter: grayscale(0%);
        }
      }
    }
  }
}

@media (max-width: 675px) {
  .swg {
    font-size: 2.5rem;
  }
}
