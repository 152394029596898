.bookings {
  box-shadow: -7px 7px 0px 0px #d50200;
  // padding: 15px 90px;
  width: 300px;
  height: 60px;

  background-color: #ffe001;
  text-decoration: none;
  color: black;
  font-family: "Bowlby One", serif;
  font-weight: 800;
  border: transparent;
  span {
    font-size: 1.1rem;
    transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  }
  &:hover {
    transition: 0.5s;
    background-color: rgb(80, 0, 80);
    box-shadow: none;

    span {
      transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
      color: #ffe001;
      letter-spacing: 5px;
    }
  }
}
.closed,
.soon {
  background-color: #d50200;
  color: white;
  box-shadow: none;
}
.none {
  display: none;
}
@media (max-width: 675px) {
  .bookings {
    width: 100%;
    height: 50px;
    &:hover {
      span {
        letter-spacing: 6px;
      }
    }
    span {
      font-size: 3vw;
    }
  }
  .closed:hover,
  .soon:hover {
    background-color: #d50200;
    span {
      letter-spacing: initial;
    }
  }
}
