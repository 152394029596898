.ticket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: "Inter";
  border-bottom: 1px solid white;
  padding: 28px 30px;
  &__information {
    display: flex;
    align-items: center;
    line-height: 1.6;
    font-size: 16px;

    &__date {
      line-height: 1.2;
      text-align: center;
      p {
        font-size: 95%;
        font-weight: 700;
      }
      h2 {
        font-weight: 800;
      }
    }
    &__details {
      margin-left: 26px;
      &__day,
      &__name {
        font-weight: 700;
      }
      &__venue {
        font-weight: 300;
      }
    }
  }
  .sun {
    width: 10px;
    margin: 0 10px 2px;
  }
  &:hover {
    background-color: #5854547e;
    transition: 0.4s;
  }
}
@media (min-width: 1360px) {
  .ticket {
    &__information {
      font-size: 1.15vw;
    }
  }
}
// @media (max-width: 960px) {
//   .ticket {
//     &__information {
//       font-size: 1.5vw;
//     }
//   }
// }
@media (max-width: 900px) {
  .ticket {
    flex-direction: column;
    padding: 30px 0px;
    align-items: flex-start;
    &__information {
      margin-bottom: 12px;
      // font-size: 3vw;
    }
  }
  .ticketSaleBtn {
    width: 100%;
  }
  .ticket:hover {
    background: transparent;
  }
}
@media (max-width: 600px) {
  .ticket {
    &__information {
      font-size: 14px;

      &__date {
        p {
          font-size: 12px;
        }
      }
    }
  }
}
