.videoContainer {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  display: flex;
  &::after {
    position: fixed;
	content: "";
    background: url("../../assets/images/texture.jpg");
	background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 80%;
    mix-blend-mode: overlay;
    z-index: 10;
  }
}

.video {
  position: absolute;
  width: 90%;
  left: 5%;
  min-height: 100%;
  object-fit: cover;
  margin: auto;
  background: #000;
  border: none;
}
.scrollVideo {
  position: absolute;
  z-index: -2;
  width: 100%;
  object-fit: cover;
}
.higherBtn {
  position: relative;
  z-index: 11;
  margin-top: 70px;
  padding: 1rem;
}
.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 100px 32px 40px;
  line-height: 1.5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 60px;
  }
}

.titleImage {
  width: 50%;
}

@media (max-width: 900px) {
  .titleImage {
    width: 80%;
  }
  .higherBtn {
    width: 90%;
    max-width: 300px;
  }
}
@media (max-width: 400px) {
  .titleImage {
    width: 90%;
  }
}
