.footerDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3% 8%;
	background-color: black;
	.socialsDiv {
		display: flex;
		max-width: 125px;
		width: 20%;
		justify-content: space-between;
		a {
			max-width: 25px;
			width: 25%;
			img {
				width: 100%;
			}
		}
	}
	p {
		color: #d0d5dd;
		font-family: "Inter";
		font-size: 0.9rem;
	}
}
@media (max-width: 900px) {
	.footerDiv {
		padding: 3% 5%;
		.socialsDiv {
			width: 25%;
			a {
				width: 20%;
				img {
					width: 100%;
				}
			}
		}
	}
}
@media (max-width: 725px) {
	.footerDiv {
		p {
			font-size: 12px;
		}
	}
}
